<template>
  <footer role="contentinfo" class="col">
    <div id="footer-info" class="row no-gutter">
      <div class="col-12 col-sm order-1 text-center mt-4 mt-sm-0">
        <div class="d-sm-inline-block text-left">
          <p class="font-weight-bold footer-title">Alabama Pathways</p>
          <div class="row mt-3">
            <div class="col-10">Contact our Help Desk:</div>
          </div>

          <div class="row">
            <div class="col-1">
              <icon icon="envelope" class="fa-1_5x" style="float: left"></icon>
            </div>
            <a class="col-10" href="mailto:ALPathways@dhr.alabama.gov">Send Email</a>
          </div>
          <!--added Contact Phone  -->
          <div class="row">
            <div class="col-1">
              <icon icon="phone-square-alt" class="fa-1_5x" style="float: left"></icon>
            </div>
            <a class="col-10" href="tel:334-353-9618">334-353-9618</a>
          </div>
          <!--added Time  -->
          <div class="row mt-3">
            <div class="col-1"></div>
            <div class="col-10 footer-text-small" style="padding-top: 0">9am - 4pm Central</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm order-2 text-center">
        <div class="d-sm-inline-block text-left mt-4 mt-sm-0">
          <p class="mt-2 mt-sm-0 font-weight-bold footer-title">Resources</p>
          <a href="https://www.alabamaqualitystars.org" target="_blank">Alabama Quality STARS</a>
          <a
            href="/api/file/s/Alabama-Pathways-Report"
            target="_blank"
            class="mt-2 d-block"
            >Alabama Pathways<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
          <a href="https://dhr.alabama.gov/wp-content/uploads/2019/07/AELG.pdf" target="_blank" class="mt-2 d-block"
            >Alabama Early Learning Guidelines<icon
              icon="file-pdf"
              class="ml-2 fa-1_5x align-middle position-absolute"
            ></icon
          ></a>
          <a href="/api/file/s/training-legend" class="mt-2 d-block"
            >Training Legend<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
          <a href="/api/file/s/faq" class="mt-2 d-block"
            >FAQ<icon icon="file-pdf" class="ml-2 fa-1_5x align-middle"></icon
          ></a>
        </div>
      </div>
      <div class="col order-5 order-xl-3 text-center mt-4 mt-lg-0">
        <img
          id="footer-logo"
          src="../../../images/logo/al_dhr_logo.png"
          alt="Alabama Department of Human Resources logo"
        />
      </div>
      <div class="col-12 col-sm order-3 order-xl-4 text-center">
        <div class="d-sm-inline-block text-left mt-4 mt-sm-0">
          <p class="mt-2 mt-sm-0 font-weight-bold footer-title">Partners</p>
          <div>
            <a
              href="https://dhr.alabama.gov/child-care/quality-overview/child-care-quality-enhancement-agencies-comprehensive/"
              target="_blank"
            >
              Quality Enhancement Agencies, Comprehensive
            </a>
            <a
              href="https://dhr.alabama.gov/child-care/quality-overview/child-care-quality-enhancement-agencies/"
              target="_blank"
              class="mt-2 d-flex"
            >
              Quality Enhancement Agencies, Targeted
            </a>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg mt-xl-0 order-4 text-center">
        <div class="d-sm-inline-block text-left text-sm-center text-lg-left mt-4 mt-sm-0">
          <p class="font-weight-bold footer-title">Provided By</p>
          <a href="https://dhr.alabama.gov/child-care/" target="_blank">Alabama Department of Human Resources</a>
          <br />
          <!--Small portion-->
          <span class="footer-text-small">
            <address>
              50 N. Ripley St<br />
              P.O. Box 304000<br />
              Montgomery, AL 36130-4000
            </address>
          </span>
          <!--END small portion-->
        </div>
      </div>
    </div>
    <div id="footer-info-box-shadow-container" class="row">
      <div id="footer-info-box-shadow"></div>
    </div>
    <div id="footer-copyright" class="row white pl-1 pl-sm-4">
      <div class="col">
        &copy; {{ year }} Alabama Department of Human Resources all rights reserved. This project is funded through a
        contract with the Alabama Department of Human Resources.
        <a href="https://oit.alabama.gov/resources/privacy-statements/" target="_blank" rel="noopener noreferrer"
          >Terms & Conditions</a
        >
      </div>
    </div>
    <modal
      v-show="isModalVisible"
      @close="closeModal"
      :isModalVisible="isModalVisible"
      :mustViewAll="false"
      :showCopyright="true"
      :modalBusy="isBusy"
    >
      <h2 slot="header">Terms and Conditions</h2>
      <div class="text-left row" slot="body">
        <div class="col">
          <h3>Acceptable Use and Linking Statement</h3>
        </div>
      </div>
    </modal>
    <span id="commithash" style="display: none">{{ githash }}</span>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      isModalVisible: false,
      acceptableUseText: '',
      year: new Date().getFullYear().toString(),
      // This is injected from webpack.config.js line below tells eslint that
      /*global GIT_HASH*/
      githash: process.env.GIT_HASH,
      isBusy: false
    };
  },
  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    async downloadAcceptableUse() {
      this.$emit('busy', true);
      this.isBusy = true;
      const fileCode = 'acceptable-use';
      await this.$downloadStaticFile(fileCode);
      this.$emit('busy', false);
      this.isBusy = false;
    },
    async downloadFaq() {
      this.$emit('busy', true);
      this.isBusy = true;
      const fileCode = 'faq';
      await this.$downloadStaticFile(fileCode);
      this.$emit('busy', false);
      this.isBusy = false;
    }
  }
};
</script>
